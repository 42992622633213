<template>
  <div class="register">
    <v-snackbar v-model="codeSentNotification" :timeout="3000" location="top center" transition="slide-y-transition" height="80" variant="elevated" color="success" elevation="24">
      <p class="message">Kod uspješno poslan.</p>
    <template v-slot:actions>
        <v-btn color="white" variant="text" @click="codeSentNotification = false">
        <v-icon size="large">mdi-close</v-icon>
        </v-btn>
    </template>
    </v-snackbar>
    <v-snackbar v-model="errorNotification" :timeout="3000" location="top center" transition="slide-y-transition" height="80" variant="elevated" color="#f58220" elevation="24">
      <p class="message">Desila se greška, pokušaj kasnije!</p>
    <template v-slot:actions>
        <v-btn color="white" variant="text" @click="errorNotification = false">
        <v-icon size="large">mdi-close</v-icon>
        </v-btn>
    </template>
    </v-snackbar>
    <v-snackbar v-model="activationNumError" :timeout="3000" location="top center" transition="slide-y-transition" height="80" variant="elevated" color="#f58220" elevation="24">
      <p class="message">Aktivacioni broj nije validan, pokušaj ponovo!</p>
    <template v-slot:actions>
        <v-btn color="white" variant="text" @click="activationNumError = false">
        <v-icon size="large">mdi-close</v-icon>
        </v-btn>
    </template>
    </v-snackbar>
    <v-container>
      <v-row justify="center">
        <v-col xs="12" sm="8" md="6" lg="6" xl="4">
          <div class="form">
            <v-window v-model="this.step">
              <v-window-item :value="1" disabled>
                  <h1 class="text-3xl font-bold mb-4"><v-chip color="red">{{this.step}}</v-chip> Registruj se <span class="text-lg font-medium mr-1"> > E-mail </span> </h1>
                  <p class="pt-3">Unesi svoju e-mail adresu kako bi proces registracije započeo. <br> Nakon toga, biće ti poslan e-mail sa jednokratnim aktivacionim kodom koji trebaš unijeti da bi se nastavio proces registracije.</p>
                  <br>
                  <v-text-field
                    class="mt-3"
                    label="E-mail adresa"
                    clearable
                    placeholder="johndoe@gmail.com"
                    type="email"
                    prepend-inner-icon="mdi-email-outline"
                    v-model="this.newUser.email"
                    :rules="[rules.required, rules.email]"
                    @keyup.enter="identifyUserByEmail"
                  ></v-text-field>
                  <v-row v-if="this.profileExists">
                    <v-col>                  
                      <span class="text-red font-semibold italic">Nalog postoji.</span>
                    </v-col>
                    <v-col class="text-right">
                        <!-- <router-link class="no-underline" :to="{name: 'ForgottenPassword'}">
                        <span class="pl-3">Zaboravili ste lozinku?</span>
                        </router-link> -->
                    </v-col>
                  </v-row>
              </v-window-item>
              <v-window-item :value="2" disabled>
                  <h1 class="text-3xl font-bold"><v-chip color="red">{{this.step}}</v-chip> Registruj se <span class="text-lg font-medium mr-1"> > Potvrdi e-mail </span></h1>
                  <p class="font-medium pt-2">Na e-mail adresu <span class="font-bold text-red-800 text-xl">{{ this.newUser.email }}</span> smo poslali aktivacioni kod koji je potrebno unijeti u nastavku. <br>
                  Ukoliko nemaš e-mail u dolaznoj pošti, provjeri i <span class="text-red-800 font-semibold">spam</span> folder.</p>
                  <br>
                  <v-row>
                    <v-col v-if="this.sendCodeAgain"><span class="text-red mb-2 font-semibold italic">Pošalji ponovo <v-icon>mdi-arrow-right-circle</v-icon></span></v-col>
                    <v-col v-else></v-col>
                    <v-col class="text-right">
                        <v-chip @click="resendVerificationCode" link variant="elevated" class="mb-2" size="small" color="blue" label text-color="white">
                        <v-icon start icon="mdi-email-arrow-right"></v-icon> 
                            Pošalji ponovo
                        </v-chip>
                    </v-col>
                  </v-row>
                  <v-text-field
                    class="mt-3"
                    label="Unesite aktivacioni kod"
                    clearable
                    type="text"
                    prepend-inner-icon="mdi-shield-key-outline"
                    v-model="this.newUser.activation_code"
                    :rules="[rules.required, rules.length, rules.type]"
                    @keyup.enter="validateEmailAddress"
                  ></v-text-field>
                  <v-row v-if="this.invalidCodeError">
                    <v-col><span class="text-red">Netačan kod.</span></v-col>
                    <v-col class="text-right">
                        <v-chip v-if="this.sendCodeAgain == false" @click="resendVerificationCode" link variant="elevated" class="mb-2" size="small" color="blue" label text-color="white">
                        <v-icon start icon="mdi-email-arrow-right"></v-icon> 
                            Pošalji ponovo
                        </v-chip>
                    </v-col>
                  </v-row>
              </v-window-item>
              <v-window-item :value="3" disabled>
                  <h1 class="text-3xl font-bold"><v-chip color="red">{{this.step}}</v-chip> Registruj se <span class="text-lg font-medium mr-1"> > Lični podaci i lozinka </span></h1>
                  <p class="font-medium pt-2">Unesi lične podatke i kreiraj lozinku koju ćeš koristiti za prijavu na svoj nalog. 
                      Lozinka mora sadržavati minimalno 8 znakova uključujući bar jedno veliko slovo, malo slovo, broj i specijalni znak.</p>
                  <br>
                  <v-text-field
                    label="Ime"
                    hint="Unesite svoje ime"
                    clearable
                    type="text"
                    prepend-inner-icon="mdi-account"
                    v-model="this.newUser.first_name"
                    :rules=[rules.required]
                  ></v-text-field>
                  <v-text-field
                    vraiant="underlined"
                    label="Prezime"
                    hint="Unesite svoje prezime"
                    clearable
                    type="text"
                    prepend-inner-icon="mdi-account-outline"
                    v-model="this.newUser.last_name"
                    :rules=[rules.required]
                  ></v-text-field>
                  <v-text-field
                    v-model="this.newUser.password"               
                    :append-inner-icon="this.show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="this.show1 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Unesite lozinku"
                    class="input-group--focused"
                    @click:append-inner="this.show1 = !this.show1"
                    prepend-inner-icon="mdi-lock-outline"
                    :rules=[rules.password]
                  ></v-text-field>
                  
                  <!-- <v-row v-if="this.invalidPasswordFormatError">
                  <v-col><span class="text-red">Format lozinke nije zadovoljen.</span></v-col>
                  </v-row> -->
                  <!-- <p v-if="this.invalidPasswordFormatError" class="text-red fs-6">Format lozinke nije zadovoljen.</p> -->
                  <v-text-field
                    v-model="this.newUser.confirmed_password"
                    :append-inner-icon="this.show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="this.show2 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Potvrdite lozinku"
                    class="input-group--focused" 
                    @click:append-inner="this.show2 = !this.show2"
                    prepend-inner-icon="mdi-lock"
                    @keyup.enter="checkConfirmedPassword"
                  ></v-text-field>
                  <p v-if="this.invalidPasswordConfirmedError" class="text-red fs-6">Potvrda lozinke netačna.</p>
              </v-window-item>
              <v-window-item :value="4" disabled>
                  <h1 class="text-3xl font-bold"><v-chip color="green">{{this.step}}</v-chip> Registruj se<span class="text-lg font-medium mr-1"> >Imaš li MOVE TV+Mob NET paket? </span></h1>
                  <p class="font-medium pt-2">Klikni na dugme Kreiraj da završiš registraciju. Ukoliko imaš MOVE TV+Mob Net paket, unesi aktivacioni broj.</p>
                  <div class="mb-5" :style="biggerScreen ? 'display: flex; flex-direction: row; justify-content: center; align-items: center;' : 'display: flex; flex-direction: column; justify-content: center; align-items: center;'">
                    <img v-if="!activationNumExists" src="../assets/images/movetvnet.png" alt="logo" width="230" height="190" style="border-radius: 20px;" class="pr-2 mt-3">
                    <v-checkbox-btn
                      v-model="activationNumExists"
                      class="pb-4 mt-3"
                      label="Imam aktivacioni broj"
                    ></v-checkbox-btn>
                  </div>
                  <v-text-field
                    v-if="activationNumExists"
                    v-model="this.newUser.activationNumber"
                    label="Unesi aktivacioni broj"
                    maxlength="19"
                    @input="formatCardNumber"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    clearable
                    :rules=[rules.luhn_number]
                    @keyup.enter="createAccount"
                  ></v-text-field>
              </v-window-item>
            </v-window>
              <v-row no-gutters>
                <v-col cols="6" class="text-left">
                    <v-btn v-if="this.step > 1" @click="this.step--" class="mt-3 bg-black" variant="tonal"  size="large" elevation="3"> 
                    <span class="font-bold">Nazad</span>
                    </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn v-if="this.step == 1" @click="identifyUserByEmail" :loading="this.loading" :disabled="validateEmailFormat()" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                    <span class="font-bold">Nastavi</span>
                  </v-btn>
                  <v-btn v-if="this.step == 2" @click="validateEmailAddress" :loading="this.loading" :disabled="validateCodeFormat()" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                    <span class="font-bold">Nastavi</span>
                  </v-btn>
                  <v-btn v-if="this.step == 3" @click="checkConfirmedPassword()" :disabled="validatePasswordAndName()" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                    <span class="font-bold">Nastavi</span>
                  </v-btn>
                  <v-btn v-if="this.step == 4" :loading="createAccountLoading" @click="createAccount" :disabled="validatePersonalInfo()" class="right bg-success mt-3" variant="tonal" size="large" elevation="3">
                    <span class="font-bold">Kreiraj</span>
                  </v-btn>
                </v-col>
              </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import {ref, onBeforeMount, onMounted} from 'vue'
  import CryptoJS from 'crypto-js';
  import moment from 'moment';
import { toHandlers } from 'vue';
  
  export default {
      data(){
        return{ 
          tab: null,
          step: 1,
          show1: false,
          show2: false,
          password: 'Lozinka',
          info: null,
          profileExists: false,
          sendCodeAgain: false,
          profileId: null,
          codeValidation: null,
          codeSentNotification: false,
          errorNotification: false,
          activationNumError: false,
          invalidPasswordConfirmedError:false,
          invalidPasswordFormatError: false,
          passwordValidationErrors: [],
          invalidPassword: false,
          invalidCodeError:false,
          validInvoiceFormat:true,
          createAccountLoading: false,
          rules:{
            required: value => !!value || "Obavezno polje.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Neispravna e-mail adresa.'
            },
            length: value => value.length == 6 || "Potrebno unijeti 6 karaktera.",
            type: value => typeof value == 'string' || "",
            password: value => this.validatePasswordFormat() || "Neispravan format lozinke.",
            number: value => typeof value == 'number' || "Dozvoljeni su samo brojevi.",
            invoice_num: value => value.length == 10 || "Potrebno je unijeti 10 karaktera.",
            invoice_format: value => this.controlNumber() || "Neispravan broj računa.",
            luhn_number: value => this.validateLuhn() || "Aktivacioni broj nije validan."
          },
          newUser: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirmed_password: '',
            loggedIn: false,
            activation_code: '',
            activationNumber: null
          },
          existingUser:{
            invoice_num: '',
            email: '',
            phone_num: ''
          },
          loading: false,
          invoice_format: false,
          activationNumExists: false,
          createAccountResponse: null
        }
      },
  
      computed:{
        biggerScreen () {
          if(this.$vuetify.display.width >= 960){
            return true;
          }else{
            return false;
          }
        },
      },
  
      methods:{

        validateLuhn() {

          if(this.newUser.activationNumber == null || this.newUser.activationNumber == '' || this.newUser.activationNumber.length < 19){
            return false;
          }else if(this.newUser.activationNumber.length == 19){

            let number = this.newUser.activationNumber.replace(/-/g, '');
            
            let arr = (number + '')
              .split('')
              .reverse()
              .map(x => parseInt(x));
            let lastDigit = arr.splice(0, 1)[0];
            let sum = arr.reduce(
              (acc, val, i) =>
                i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
              0
            );
            sum += lastDigit;
            return sum % 10 === 0;
          }else{
            return true;
          }
        },

        formatCardNumber(){
          this.newUser.activationNumber = this.newUser.activationNumber
          //.replace(/\s+/g, '')
          //.replace(/(\d{4})/g, '$1 ')
          .replace(/-/g, '')
          .replace(/(\d{4})(?=\d)/g, '$1-')
          .trim();
        },  
  
        validateEmailFormat(){
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let validEmail = pattern.test(this.newUser.email);
          
          if(this.newUser.email == '' || validEmail == false){
            return true;
          }else{
            return false;
          }
        },
  
        validateCodeFormat(){
          if(this.newUser.activation_code == '' || this.newUser.activation_code.length != 6){
            return true;
          }else{
            return false;
          }
        },
  
        validatePasswordFormat(){

          //Password rules
          const minLength = 8;
          const hasUpperCase = /[A-Z]/.test(this.newUser.password);
          const hasLowerCase = /[a-z]/.test(this.newUser.password);
          const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(this.newUser.password);
          const hasNumber = /[0-9]/.test(this.newUser.password);

          //Check each rule

          if (this.newUser.password.length < minLength) {
            this.invalidPassword = true;
            return false;
          }
          else if (!hasUpperCase) {
            this.invalidPassword = true;
            return false;        
          }
          else if (!hasLowerCase) {
            this.invalidPassword = true;
            return false;          
          }
          else if (!hasSpecialChar) {
            this.invalidPassword = true;
            return false;        
          }
          else if (!hasNumber) {
            this.invalidPassword = true;
            return false;     
          }
          else{
            this.invalidPassword = false;
            return true;
          }

          // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

          // const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$");

          //   if (!this.newUser.password) {
          //       this.invalidPasswordFormatError = false;
          //       console.log("nema nista");
          //       return true;
          //   }
          //   if (passwordRegex.test(this.newUser.password)) {
          //       this.invalidPasswordFormatError = false;
          //       console.log("Ispravan format");
          //       return false;
          //   } else {
          //       this.invalidPasswordFormatError = true;
          //       console.log("Neispravan format");
          //       return true;
          //   }



          //stari kod

          // this.invalidPasswordFormatError = false;
  
          // const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
          // let contains_eight_characters = true;
          // let contains_number = false;
          // let contains_uppercase = false;
          // let contains_special_character = false;
  
          // if (this.newUser.password.length >= 8) {
          //   contains_eight_characters = true;
          // } else {
          //   contains_eight_characters = false;
          // }
  
          // contains_number = /\d/.test(this.newUser.password);
          // contains_uppercase = /[A-Z]/.test(this.newUser.password);
          // contains_special_character = format.test(this.newUser.password);
  
          // if(contains_eight_characters === true &&
          //    contains_special_character === true &&
          //    contains_uppercase === true &&
          //    contains_number === true ){
          //     this.invalidPasswordFormatError = false;
          //     return false;
          //   }else{
          //     if(this.newUser.password != '') this.invalidPasswordFormatError = true;
          //     return true;
          //   }
  
        },
  
        checkConfirmedPassword(){
  
          if(this.newUser.password == this.newUser.confirmed_password){
            this.step++;
          }else{
            this.invalidPasswordConfirmedError = true
          }
        },

        validatePasswordAndName(){
          if(this.newUser.first_name == '' || this.newUser.last_name == ''){
            return true;
          }else if(this.newUser.first_name == null || this.newUser.last_name == null){
            return true;
          }else if(this.invalidPassword){
            return true;
          }else if(this.newUser.password != this.newUser.confirmed_password){
            return true;
          }else{
            return false;
          }
        },

        validatePassConfirm(){
          if(this.invalidPassword){
            return true;
          }else if(this.newUser.password != this.newUser.confirmed_password){
            return true;
          }else{
            return false;
          }
        },

        validateNameSurname(){
          if(this.newUser.first_name == '' || this.newUser.last_name == ''){
            return true;
          }else{
            return false;
          }
        },
  
        validatePersonalInfo(){
  
          if(this.newUser.first_name == '' || this.newUser.last_name == ''){
            return true;
          }else if(this.activationNumExists){
            if(this.newUser.activationNumber == null || this.newUser.activationNumber == ""){
              return true;
            }else{
              if(this.newUser.activationNumber.length == 19){
                let number = this.newUser.activationNumber.replace(/-/g, '');
            
                let arr = (number + '')
                  .split('')
                  .reverse()
                  .map(x => parseInt(x));
                let lastDigit = arr.splice(0, 1)[0];
                let sum = arr.reduce(
                  (acc, val, i) =>
                    i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
                  0
                );
                sum += lastDigit;

                if(sum % 10 === 0){
                  return false;
                }else{
                  return true;
                }
                // return sum % 10 === 0;
              }else{
                return true;
              }
            }
          }
          else{
            return false;
          }
        },  
  
      //   controlNumber() {
  
      //     if(this.existingUser.invoice_num == '' || this.existingUser.invoice_num.length != 10){
      //       return false;
      //     }
  
      //     let p_string_in = this.existingUser.invoice_num.slice(0,9);
      //     let last_char = this.existingUser.invoice_num.slice(-1);
  
      //     let v_length = p_string_in.length;
      //     let v_sum = 0;
      //     let v_count = 2;
      //     let v_mod;
          
      //     for (let i = 1; i <= v_length; i++) {
      //       if (['0','1','2','3','4','5','6','7','8','9'].includes(p_string_in.substr(i*(-1), 1))) {
      //         v_sum += v_count * Number(p_string_in.substr(i*(-1), 1));
      //       }
      //       v_count += 1;
      //     }
          
      //     v_mod = v_sum % 11;
      //     if (v_mod === 0 || v_mod === 1) {
      //       // this.invoice_format = false;
      //       return false;
      //     } else {
      //       if(String(last_char) == String(11 - v_mod)){
      //         console.log("Ispravan format");
      //         return true;
      //         //Nastavi dalje
      //       }else{
      //         return false;
      //       }
      //     }
      // },
  
      //Api pozivi
  
      async identifyUserByEmail(){
  
        //provjera da li email postoji u bazi

        const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const signatureString = date + this.newUser.email;
          
        const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
  
        this.loading = true;
        let raw = JSON.stringify({
        "Request": {
          "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
          "Date": date,
          "Method": "identifyUserByEmail",
          "Signature": signature,
          "Params": {
            "email": this.newUser.email
            }
          }
        });
  
        try{
          await fetch(process.env.VUE_APP_ROOT_API, {
            method: "POST",
            headers:{
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/json",
              "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
            },
            body: raw,
            redirect: 'follow'
            
          })
            .then(res => res.json())
            .then(data => this.profileId = data.Response.resData.uniResponse)
            .catch(error => console.log('error', error));
        }catch(error){
          alert("Desila se greška, pokušajte kasnije!");
        }

        this.checkMail();
        
      },
  
      checkMail(){
        if(this.profileId == "-9"){
          // Mail ne postoji!
          this.profileExists = false;
          this.initializeUserRegistration();       
        }else if(this.profileId == "101"){
          this.loading = false;
          //Mail postoji, ali kod nije validiran!
          this.profileExists = false;
          this.sendCodeAgain = true;
          this.step++;
        }else if(this.profileId == "102"){
          this.loading = false;
          // Mail postoji, kod je već unesen!
          this.profileExists = false;
          this.step = 3;
        }
        else{
          // Nalog već postoji!
          this.profileExists = true;
          this.loading = false;
        }
      },
  
      initializeUserRegistration(){
  
      //slanje verifikacionog koda na upisani email i provjera da li je već poslan

      const ident_type = "EMAIL";
      const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
      const date = moment().format('YYYY-MM-DD HH:mm:ss');
      const signatureString = date + ident_type + this.newUser.email;
          
      const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

      let rawData = JSON.stringify({
          "Request": {
            "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
            "Date": date,
            "Method": "initializeUserRegistration",
            "Signature": signature,
            "Params": {
              "ident_type": ident_type,
              "username": this.newUser.email
            }
          }
        });
  
        try{
          fetch(process.env.VUE_APP_ROOT_API, {
            method: "POST",
            headers:{
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/json",
              "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
            },
            body: rawData,
            redirect: 'follow'
            
          })
            .then(res => res.json())
            .then(data => data.Response.resData == "OK" ? [this.step++, this.loading = false] : [this.errorNotification = true, this.loading = false])
            .catch(error => console.log('error', error));
        }catch(error){
          alert("Desila se greška, pokušajte kasnije!");
        }
        
        
      },
  
      validateEmailAddress(){
  
        //unos verifikacionog koda

        const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const signatureString = date + this.newUser.email + this.newUser.activation_code;
            
        const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
          
          this.loading = true;
          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "validateEmailAddress",
              "Signature": signature,
              "Params": {
                "username": this.newUser.email,
                "verification_code": this.newUser.activation_code
              }
            }
          });
    
          try{
            fetch(process.env.VUE_APP_ROOT_API, {
              method: "POST",
              headers:{
                "Content-Type": "application/json",
                "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
              },
              body: raw,
              redirect: 'follow'
              
            })
              .then(res => res.json())
              .then(data => data.Response.resData == "OK" || data.Response.resData == "VALID" ? [this.step++, this.loading = false] : [this.invalidCodeError = true, this.loading = false])
              .catch(error => console.log('error', error));
          }catch(error){
            alert("Desila se greška, pokušajte kasnije!");
          }
  
      },
  
      resendVerificationCode(){

        const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const signatureString = date + this.newUser.email;
            
        const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
  
        let raw = JSON.stringify({
          "Request": {
            "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
            "Date": date,
            "Method": "resendVerificationCode",
            "Signature": signature,
            "Params": {
              "username": this.newUser.email
            }
          }
        });
  
        try{
          fetch(process.env.VUE_APP_ROOT_API, {
            method: "POST",
            headers:{
              "Content-Type": "application/json",
              "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
            },
            body: raw,
            redirect: 'follow'
            
          })
            .then(res => res.json())
            .then(data =>  this.codeSentNotification = true)
            .catch(error => console.log('error', error));
        }catch(error){
          alert("Desila se greška, pokušajte kasnije!");
        }
  
      },
  
      async createAccount(){

        this.createAccountLoading = true;

        let activation_number;
        if(this.newUser.activationNumber == '' || this.newUser.activationNumber == null){
          activation_number = 'NONE';
        }else{
          activation_number = this.newUser.activationNumber.replace(/-/g, '');
        }

        // let accountCreated = false;
        const ident_type = "EMAIL";
        const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const signatureString = date + ident_type + this.newUser.email + this.newUser.confirmed_password + this.newUser.first_name + this.newUser.last_name + activation_number;
            
        const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
  
        let raw = JSON.stringify({
          "Request": {
            "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
            "Date": date,
            "Method": "createSiptvAccount",
            "Signature": signature,
            "Params": {
              "ident_type": ident_type,
              "username": this.newUser.email,
              "password": this.newUser.confirmed_password,
              "first_name": this.newUser.first_name,
              "last_name": this.newUser.last_name,
              "activation_number": activation_number
            }
          }
        });
        
        //Stari kod
        // try{
        //  await fetch(process.env.VUE_APP_ROOT_API, {
        //     method: "POST",
        //     headers:{
        //       "Content-Type": "application/json"
        //     },
        //     body: raw,
        //     redirect: 'follow'
            
        //   })
        //     .then(res => res.json())
        //     .then(data => data.Response.resData == "OK" ? accountCreated = true : accountCreated = false)
        //     .catch(error => console.log('error', error));
        // }catch(error){
        //   alert("Greška na serveru!");
        // }
  
        // if(accountCreated){
        //   this.createAccountLoading = false;
        //   this.$router.push({ path: 'prijavi-se', query: {registration: 'success'}});
        // }else{
        //   this.createAccountLoading = false;
        //   this.errorNotification = true;
        // }

        //Novi kod
        try{
         await fetch(process.env.VUE_APP_ROOT_API, {
            method: "POST",
            headers:{
              "Content-Type": "application/json"
            },
            body: raw,
            redirect: 'follow'
            
          })
            .then(res => res.json())
            .then(data => this.createAccountResponse = data.Response)
            .catch(error => console.log('error', error));
        }catch(error){
          alert("Desila se greška, pokušajte kasnije!");
        }

        if(this.createAccountResponse != null && this.createAccountResponse.apiResult[0].result_code == 0 && this.createAccountResponse.resData == "OK"){
            this.createAccountLoading = false;
            this.$router.push({ path: 'prijavi-se', query: {registration: 'success'}});
        }
        else if(this.createAccountResponse != null && this.createAccountResponse.apiResult[0].result_code == 0 && this.createAccountResponse.resData == "ERROR: -11"){
          this.createAccountLoading = false;
          this.activationNumError = true;
        }
        else{
          this.createAccountLoading = false;
          this.errorNotification = true;
        }

      }
  
    },
  
    
    beforeMount(){
      window.scrollTo(0, 0);
    },
  
    
  }
  </script>
  
  <style>
      .register{
          width: 100%;
          height: 100%;
          padding-top: 110px;
          padding-bottom: 110px;
          background-color: rgb(233, 233, 233);
      }

      .message{
        padding-bottom: 10px;
        font-family: 'Roboto-Bold';
        font-size: 16px;
      }  
  </style>